export const formatDate = function (date) {
  let dd = date.getDate();
  if (dd < 10) dd = '0' + dd;

  let mm = date.getMonth() + 1; // месяц 1-12
  if (mm < 10) mm = '0' + mm;

  let yyyy = date.getFullYear();
  while (yyyy.length < 4) yyyy.unshift('0');

  return dd + '.' + mm + '.' + yyyy;
}

export const formatISODate = function (isoDate) {
  if (isoDate) {
    let date = new Date(isoDate);
    return formatDate(date);
  } else {
    return "";
  }
}
