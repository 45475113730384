<template>
  <transition name="modal-fade">
    <div ref="modal" class="modal-backdrop" @click="clickOutside">
      <div class="modal" role="dialog">
        <div class="btn-close" @click="close">
          <md-icon>close</md-icon>
        </div>

        <div class="title-wrap">
          <h4 class="md-title">Товар №{{ product.id }}</h4>
          <div class="subheading-wrap">
            <h5 class="md-headline">
              {{ product.name }}
              <span
                class="icon"
                @click="$router.push(`/products/${product.id}`)"
              >
                <md-icon> edit </md-icon>
              </span>
            </h5>
          </div>
          <div class="description-wrap">
            <p class="md-body-1">{{ product.description }}</p>
          </div>
        </div>

        <div class="md-layout">
          <div class="images-wrap md-layout-item md-large-size-100 md-layout">
            <div
              v-for="(image, index) in productImages"
              :key="'image_' + index"
              class="md-layout-item md-large-size-20"
            >
              <img :src="image" :alt="`Image ${index}`" />
            </div>
          </div>

          <div class="md-layout-item md-large-size-50 left-column column">
            <h5 class="md-subheading">Основные параметры товара</h5>
            <div class="column options" style="margin: 0 -15px">
              <div class="params row md-layout">
                <div class="md-body-2 md-layout-item md-large-size-30">
                  Размер
                </div>
                <div class="md-body-2 md-layout-item md-large-size-30">
                  Цвет
                </div>
                <div class="md-body-2 md-layout-item md-large-size-30">
                  Кол-во
                </div>
              </div>
              <div
                v-for="option in product.product_options"
                :key="`option_${option.id}`"
                class="params row md-layout"
              >
                <div class="md-layout-item md-large-size-30">
                  {{ option.size }}
                </div>
                <div class="md-layout-item md-large-size-30">
                  <div
                    :title="option.color ? option.color.name : ''"
                    :style="{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      backgroundColor: option.color ? option.color.hex : '#fff',
                    }"
                  ></div>
                </div>
                <div class="md-layout-item md-large-size-30">
                  {{ option.quantity }}
                </div>
              </div>
            </div>

            <div class="row categories">
              <span class="md-body-2">Категория: </span>
              <span>{{ categories }}</span>
            </div>

            <div class="row purchase-price">
              <span class="md-body-2">Цена закупки: </span>
              <span>{{ product.purchase_price }}</span>
            </div>

            <div class="row purchase-price">
              <span class="md-body-2">Валюта закупки: </span>
              <span>{{ product.purchase_price_currency }}</span>
            </div>

            <div class="row price">
              <span class="md-body-2">Цена: </span>
              <span>{{ product.price }}</span>
            </div>

            <div class="row price-with-discount">
              <span class="md-body-2">Старая цена: </span>
              <span>{{ product.old_price }}</span>
            </div>

            <div class="row vendor-name">
              <span class="md-body-2">Поставщик: </span>
              <span>{{ vendorName }}</span>
            </div>

            <div class="row tags">
              <span class="md-body-2">Теги: </span>
              <span>{{ tags }}</span>
            </div>
          </div>

          <div class="md-layout-item md-large-size-50 right-column column">
            <div>
              <h5 class="md-subheading">Характеристики товара</h5>
              <div class="row brand">
                <span class="md-body-2">Бренд: </span>
                <span>{{ product.brand ? product.brand.name : '' }}</span>
              </div>

              <div class="row seasons">
                <span class="md-body-2">Сезон: </span>
                <span>{{ seasons }}</span>
              </div>

              <div class="column options">
                <div class="md-subheading">Материалы</div>

                <template v-if="product.features && product.features.length">
                  <div class="params row">
                    <div class="md-body-2">Название</div>
                    <div class="md-body-2">Значение</div>
                  </div>
                  <div
                    v-for="(material, index) in product.features"
                    :key="`material_${index}`"
                    class="params row"
                  >
                    <div>{{ material.name }}</div>
                    <div>{{ material.value }}</div>
                  </div>
                </template>

                <div v-else class="params row">Нет данных</div>
              </div>
            </div>

            <div>
              <h5 class="md-subheading">Дополнительные параметры:</h5>
              <div class="row storefronts">
                <span class="md-body-2">Витрины: </span>
                <span>{{ storefronts }}</span>
              </div>

              <div class="row removal-time">
                <span class="md-body-2">В архив: </span>
                <span>{{ getDate(product.removal_time) }}</span>
              </div>

              <div class="row region">
                <span class="md-body-2">Регион: </span>
                <span>{{ product.region }}</span>
              </div>

              <div class="row weight">
                <span class="md-body-2">Точный вес: </span>
                <span>{{ product.weight }}</span>
              </div>

              <div class="row responsible">
                <span class="md-body-2">Кто добавил: </span>
                <span>{{ responsibleUser }}</span>
              </div>

              <div class="row created-at">
                <span class="md-body-2">Дата добавления: </span>
                <span>{{ getDate(product.created_at) }}</span>
              </div>

              <div class="row status">
                <span class="md-body-2">Снято с публикации: </span>
                <span>{{ !product.status ? "Да" : "Нет" }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { formatISODate } from "@/helpers/formatDate";

export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters("additionalData", ["getVendorNameById"]),

    categories() {
      return this.product?.categories?.length
        ? this.product.categories
            .flat()
            .map((item) => item.category.name)
            .join(", ")
        : "";
    },

    colorOptions() {
      return this.product?.options?.length
        ? this.product.options.filter((option) => option.attribute_id == 2)
        : [];
    },

    productImages() {
      let images = [];

      this.product?.media?.length &&
        this.product.media.forEach((media) => {
          images.push(media.original_url);
        });

      return images;
    },

    responsibleUser() {
      return this.product?.admin_product?.admin?.nickname
        ? this.product.admin_product.admin.nickname
        : "";
    },

    seasons() {
      return this.product?.product_seasons?.length
        ? this.product.product_seasons
            .flat()
            .map((item) => item.season.name)
            .join(", ")
        : "";
    },

    sizeOptions() {
      return this.product?.options?.length
        ? this.product.options.filter((option) => option.attribute_id == 1)
        : [];
    },

    storefronts() {
      return this.product?.storefronts?.length
        ? this.product.storefronts
            .flat()
            .map((item) => item.storefront.title)
            .join(", ")
        : "";
    },

    tags() {
      return this.product?.tags?.length
        ? this.product.tags
            .flat()
            .map((tag) => tag.title)
            .join(", ")
        : "";
    },

    vendorName() {
      return this.product?.vendor_id &&
        typeof this.product.vendor_id == "number"
        ? this.getVendorNameById(this.product.vendor_id)
        : "";
    },
  },

  async mounted() {
    this.$store.commit("SET_SHOW_LOADER", true);
    await this.getAdditionalData();
    this.$store.commit("SET_SHOW_LOADER", false);
  },

  methods: {
    ...mapActions("additionalData", ["getAdditionalData"]),

    clickOutside(e) {
      if (e.target.classList.contains("modal-backdrop")) {
        this.$emit("close");
      }
    },

    close() {
      this.$emit("close");
    },

    getDate(isoDate) {
      return formatISODate(isoDate);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10001;

  .modal {
    position: relative;
    height: auto;
    padding: 20px;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 5px;
    background: #fff;
    min-width: 500px;
    max-width: 900px;
  }

  .btn-close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 20px;
    color: #c4c4c4;
  }
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.title-wrap {
  .md-title,
  .md-headline,
  .md-body-1 {
    text-align: center;
  }

  .md-headline {
    position: relative;

    .icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(calc(100% + 10px), -50%);
      cursor: pointer;
      display: flex;
    }
  }

  .subheading-wrap {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }

  .description-wrap {
    margin-top: 10px;
  }
}

.images-wrap {
  margin: 20px 0;
  justify-content: center;
  align-items: center;
}

.options {
  align-items: center;
}

.params {
  justify-content: center;
  width: 100%;
}

.categories {
  margin-top: 15px;
}

.row,
.column {
  display: flex;
}

.row {
  justify-content: space-between;
}

.column {
  flex-direction: column;

  .md-subheading {
    margin-top: 15px;
  }
}
</style>
